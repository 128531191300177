import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {environment} from "@environments/environment";
import {isPlatformBrowser} from "@angular/common";
import {IEnvironmentPlatforms, IUserPlatform} from '@interfaces/user/user-common.interface';
import {SharedSessionStorageService} from '@services/shared-session-storage/shared-session-storage.service';

export interface IRedirectActions {
  saveList?: boolean,
  subscription?: { planId: number, coupon?: string }
}

export interface IAppRedirect {
  platform?: string,
  subDomain?: string,
  route: string,
  actions?: IRedirectActions
}

@Injectable({
  providedIn: 'root'
})
export class AppRedirectsService {

  constructor(@Inject(PLATFORM_ID) private platformId: any,
              private sharedSessionStorageService: SharedSessionStorageService
              ) {
  }

  redirectToSSO(platform: string | null, route: string, ssoRoute?: string, action?: IRedirectActions, extraParams?: string) {
    let baseUrl = environment.platforms.sso.link;
    let parameters = this.setRedirectParams(platform, route, action);
    let fullUrl = `${baseUrl}/${ssoRoute ?? 'login'}${parameters ? parameters : ''}${extraParams ? '&' + extraParams : ''}`;
    if (isPlatformBrowser(this.platformId)) {
      window.location.href = fullUrl;
    }
  }

  redirectToPlatform(redirectInfo: { platform?: string, subDomain?: string, route?: string, actions?: IRedirectActions }) {
    let baseUrl = this.getPlatformLink(redirectInfo.platform, redirectInfo.subDomain);
    let actionsURLString = this.getActionsURLString(redirectInfo.actions);
    let fullUrl = `${baseUrl}${redirectInfo.route ?? ''}${actionsURLString ? ('?' + actionsURLString) : ''}`;
    if (isPlatformBrowser(this.platformId)) {
      window.location.href = fullUrl;
    }
  }

  redirectToAnonymous(route?: string) {
    window.location.replace(`${environment.platforms.anonymous.link}${route ?? '/home'}`)
  }

  parseRedirectParams(queryParams: any): { platform: string | null, subDomain: string | null, route: string, actions?: IRedirectActions } {
    let redirectParams: { platform: string | null, subDomain: string | null, route: string, actions?: IRedirectActions } = {
      platform: environment.platforms.b2c.code,
      subDomain: null,
      route: '/home'
    };
    if (queryParams) {
      let route = queryParams['r'];
      let platform = queryParams['pf'];
      let subdomain = queryParams['sd'];
      if (!platform && !subdomain) {
        platform = environment.platforms.b2c.code;
      }
      redirectParams = {
        platform: platform ?? null,
        subDomain: subdomain ?? null,
        route: route ?? '/'
      };
      redirectParams.actions = this.parseRedirectActionParams(queryParams);
    }
    return redirectParams;
  }

  saveRedirectParamsToSessionStorage(params: { platform: string | null, subDomain: string | null, route: string, actions?: IRedirectActions }) {
    if (window.sessionStorage) {
      let savedParams: any = {route: params.route, actions: params.actions};
      if (params.platform) {
        savedParams.platform = params.platform;
      }
      if (params.subDomain) {
        savedParams.subDomain = params.subDomain;
      }
      this.sharedSessionStorageService.setItem('redirect', JSON.stringify(savedParams));

    }
  }

  getRedirectParamsFromSessionStorage(): { platform?: string, subDomain?: string, route: string, actions?: IRedirectActions } {
    if (window.sessionStorage) {
      let sessionString = sessionStorage.getItem('redirect');
      this.sharedSessionStorageService.removeItem('redirect');
      if (sessionString) {
        return JSON.parse(sessionString);
      }
    }
    return {platform: this.getPlatformLink(), route: '/home'};
  }

  getRedirectParsedParamsFromSessionStorage(): { platform?: string, subDomain?: string, route: string, actions?: IRedirectActions } | undefined {
    if (window.sessionStorage) {
      let sessionString = sessionStorage.getItem('redirect');
      if (sessionString) {
        return JSON.parse(sessionString);
      }
    }
    return {platform: environment.platforms.b2c.code, route: '/home'};
  }

  private setRedirectParams(platform: string | null, route: string, action?: IRedirectActions): string | undefined {
    let fullRoute = undefined;
    if (route) {
      let subDomain = location.hostname.split('.')[0];
      fullRoute = `?r=${escape(route)}${platform ? '&pf=' + platform : ''}${subDomain ? '&sd=' + subDomain : ''}`;
      let actionString = this.getActionsURLString(action);
      fullRoute = `${fullRoute}${actionString ? ('&' + actionString) : ''}`;
    }
    return fullRoute;
  }

  private getActionsURLString(action?: IRedirectActions): string | undefined {
    let actionsString = undefined;
    if (action) {
      if (action.saveList) {
        actionsString = `sl=true`;
      } else if (action.subscription) {
        actionsString = `sp=${action.subscription.planId}${action.subscription.coupon ? '&spc=' + action.subscription.coupon : ''}`;
      }
    }
    return actionsString;
  }

  private parseRedirectActionParams(queryParams: any): IRedirectActions | undefined {
    let redirectParams = undefined;
    if (queryParams) {
      if (queryParams['sl']) {
        redirectParams = {saveList: true};
      } else if (queryParams['sp']) {
        redirectParams = {subscription: {planId: queryParams['sp'], coupon: queryParams['spc']}}
      }
    }
    return redirectParams;
  }

  private getPlatformLink(platform?: string, subDomain?: string): string {
    let platformLink = environment.platforms.b2c.link;
    if (platform) {
      switch (platform.toLowerCase()) {
        case environment.platforms.b2c.code.toLowerCase():
          platformLink = environment.platforms.b2c.link;
          break;
        case environment.platforms.dashboard.code.toLowerCase():
          platformLink = environment.platforms.dashboard.link;
          break;
        case environment.platforms.admin.code.toLowerCase():
          platformLink = environment.platforms.admin.link;
          break;
        case environment.platforms.payment.code.toLowerCase():
          platformLink = environment.platforms.payment.link;
          break;
        case environment.platforms.ld.code.toLowerCase():
          platformLink = environment.platforms.ld.link;
          break;
        default:
          if (platform.split('.').length > 1) {
            platformLink = platform;
          } else {
            platformLink = subDomain ? `https://${subDomain}.${environment.platforms.base.link}` : `https://${platform}.${environment.platforms.base.link}`;
          }
          break;
      }
    } else if (subDomain) {
      platformLink = `https://${subDomain}.${environment.platforms.base.link}`;
    }
    return platformLink;
  }
}
