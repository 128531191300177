import {Component, OnDestroy, OnInit} from '@angular/core';
import {ILanguage} from '@interfaces/common/language.interface';
import {ThemeControlService, THEMES} from '@services/theme/theme-control.service';
import {Subscription} from 'rxjs';
import {LanguageControlService} from '@services/language/language-control.service';
import {AmplitudeService} from '@services/marketing/amplitude.service';
import {GoogleTagManagerService} from '@services/marketing/google-tag-manager.service';
import ar from '@login/i18n/ar.json';
import en from '@login/i18n/en.json';
import {environment} from '@environments/environment';
import {ELanguage} from "@interfaces/common/connector.interface";
import {DEFAULT_HOME_PATH} from "@constants/http.constants";

@Component({
  selector: 'alm-root-simplified-header',
  templateUrl: './simplified-header.component.html',
  styleUrls: ['./simplified-header.component.scss']
})
export class SimplifiedHeaderComponent implements OnInit, OnDestroy {

  nextLanguage: ILanguage | undefined;
  currentLanguage: ILanguage;
  currentTheme: THEMES;
  languages: ILanguage[] = [];
  private subscriptions: Subscription[] = [];

  constructor(private languageControl: LanguageControlService,
              private themeControl: ThemeControlService,
              private amplitudeService: AmplitudeService,
              private gtmService: GoogleTagManagerService) {
    this.currentLanguage = this.languageControl.currentLanguage.getValue();
    this.currentTheme = this.themeControl.currentTheme.getValue();
    this.languageControl.setTranslations('ar', ar);
    this.languageControl.setTranslations('en', en);
    let langSub = this.languageControl.currentLanguage.subscribe((lang) => {
      // this.menuDirection = lang.direction;
    });
    this.subscriptions.push(langSub);
  }

  ngOnInit(): void {
    this.languageControl.getAllLanguages().then((languages) => {
      this.languages = languages;
      this.getNextLanguage(this.currentLanguage);
    });
    let langSub = this.languageControl.currentLanguage.subscribe((currentLanguage) => {
      this.currentLanguage = currentLanguage;
      this.getNextLanguage(currentLanguage);
    });
    this.subscriptions.push(langSub);
    let themeSub = this.themeControl.currentTheme.subscribe((currentTheme) => {
      this.currentTheme = currentTheme;
    });
    this.subscriptions.push(themeSub);
  }

  onLanguageChange(language: ELanguage): void {
    this.languageControl.changeLanguageAndRedirect(language);
    this.amplitudeService.languageChangedEvent({
      Website_Language_Changed_Language: language === 'ar' ? 'Arabic' : 'English'
    })
  }

  getNextLanguage(currentLanguage: ILanguage): void {
    this.languages.map((language: ILanguage) => {
      if (language.code != currentLanguage.code) {
        this.nextLanguage = language;
      }
    });
  }

  onThemeChange(): void {
    let theme = this.currentTheme == THEMES.LIGHT ? THEMES.DARK : THEMES.LIGHT;
    this.themeControl.changeTheme(theme);
    this.gtmService.sendCustomEvent('THEME-CHANGED', {changedTo: theme == THEMES.LIGHT ? 'LIGHT' : 'DARK'});
    this.amplitudeService.themeChangedEvent({
      Website_Theme_Changed_Theme: theme === THEMES.DARK ? 'DARK' : 'LIGHT'
    })
  }

  navigateToAnonymousPage() {
    window.location.replace(`${environment.platforms.anonymous.link}/${DEFAULT_HOME_PATH}`);
  }

  ngOnDestroy(): void {
    this.subscriptions.map((sub) => {
      sub.unsubscribe();
    })
  }
}

