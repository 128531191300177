import {APP_ID, Inject, Injectable, makeStateKey, PLATFORM_ID, TransferState} from '@angular/core';
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {TokenService} from "@services/request-token/token.service";
import {ClusterNames, IHttpResponse, IRequestOptions} from "@interfaces/common/http.interface";
import {isPlatformBrowser, isPlatformServer} from "@angular/common";
import {environment} from "@environments/environment";
import {ConnectorV2Service} from "@services/connector/connector-v2.service";
import {Subscription} from "rxjs";
import {LanguageControlService} from '@services/language/language-control.service';
import {SentryService} from "@services/sentry/sentry.service";
import {EncryptionService} from "@services/request-token/encryption.service";

/**
 * A service to create http requests to the backend using custom token
 */
@Injectable({
  providedIn: 'root'
})
export class CustomHttpService {

  constructor(private httpClient: HttpClient, private tokenService: TokenService, private connectorService: ConnectorV2Service,
              @Inject(PLATFORM_ID) private platformId: object,
              @Inject(APP_ID) private appId: string,
              private state: TransferState,
              private languageControl: LanguageControlService,
              private sentryService: SentryService,
              private encryptionService: EncryptionService
  ) {
  }

  private _platformCode: string | undefined;

  get platformCode(): string | undefined {
    return this._platformCode;
  }

  set platformCode(value: string | undefined) {
    this._platformCode = value;
  }

  /**
   * Check if user session is stored in cookies
   */
  private checkIfUserLoggedIn = (): string | undefined => {
    let user = this.connectorService.connectorUser.getValue();
    if (user) {
      let uuid = user.uuid;
      if (uuid) {
        return uuid;
      }
    }
    return undefined;
  }

  processStateResponse(response: IHttpResponse, authCheck?: boolean): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (response?.success) {
        resolve(response.data);
      } else {
        if ((response.error.code == 2004 || response.error.code == 1000) && !authCheck) {
          // window.location.reload();
          reject(response.error);
        } else {
          reject(response.error);
        }
      }
    });
  }

  /**
   * Send a post request to backend with the custom token created by the Token Service
   * @param request
   * @param authCheck
   */
  sendRequest<T extends object>(request: IRequestOptions, authCheck?: boolean): Promise<any> {
    const API_STATE_KEY = makeStateKey<string>(`${this.languageControl.getCurrentLanguage().code}/${request.endpoint}`);
    if (isPlatformBrowser(this.platformId)) {
      const savedResponseState = this.state.get<string | undefined>(API_STATE_KEY, undefined);
      if (savedResponseState) {
        this.state.remove<string>(API_STATE_KEY);
        const decodedResponseState = JSON.parse(this.encryptionService.decodeBase64ToString(savedResponseState));
        return this.processStateResponse(decodedResponseState, authCheck);
      }
    }
    return new Promise<T>((resolve, reject) => {
      if (isPlatformServer(this.platformId) && request.skipOnSSR) return reject();
      let uuid = this.checkIfUserLoggedIn();
      let platformCode = this.platformCode ?? null;
      let requestToken = this.tokenService.buildRequestToken({
        sender: request.sender,
        receiver: request.receiver,
        uuid: uuid,
        body: request.body,
        platform: platformCode
      });
      let requestOptions = {
        withCredentials: true,
      }
      const apiGatewayUrl = this.buildURL(request);
      this.httpClient.post<T | IHttpResponse>(
        apiGatewayUrl,
        requestToken,
        requestOptions
      ).subscribe((response: T | IHttpResponse) => {
          if (isPlatformServer(this.platformId)) {
            const encodedResponseState = this.encryptionService.encodeStringToBase64(JSON.stringify(response));
            this.state.set<string>(API_STATE_KEY, encodedResponseState);
          }
          // this type check as we start to return data on the root response object
          // So we check if old response it will have success as a boolean
          // in old endpoints we will return the response.data
          if (response && "success" in response) {
            if (response.success) {
              resolve(response.data);
            } else {
              reject(response.error);
            }
            // in new endpoints we will return the data from root response object
          } else if (!("success" in response)) {
            resolve(response);
          }
        }, (errorResponse: HttpErrorResponse) => {
          if (errorResponse.status === 0) {
           this.sentryService.reportHttpErrorResponse('Http Request Blocked', errorResponse);
          }
          // check if the old response is still return (old services)
          if (errorResponse?.error?.success === false) {
            reject(errorResponse.error.error);
          } else {
            reject(errorResponse);
          }
        });
    });
  }

  sendCancelableRequest(
    request: { endpoint: string, sender: string, receiver: string, body: Object, clusterName: ClusterNames },
    authCheck?: boolean, skipPlatform?: boolean): { send: Promise<any>, cancel: () => void } {
    let subscription: Subscription;
    let promise: Promise<any> = new Promise<any>((resolve, reject) => {
      if (isPlatformBrowser(this.platformId) || skipPlatform) {
        let uuid = this.checkIfUserLoggedIn();
        let platformCode = this.platformCode ?? null;
        let requestToken = this.tokenService.buildRequestToken({
          sender: request.sender,
          receiver: request.receiver,
          uuid: uuid,
          body: request.body,
          platform: platformCode
        });
        let requestOptions = {
          withCredentials: true,
        }
        const apiGatewayUrl = this.buildURL(request);
        subscription = this.httpClient.post<IHttpResponse>(
          apiGatewayUrl,
          requestToken,
          requestOptions
        ).subscribe({
            next: (response: IHttpResponse) => {
              if (response.success) {
                resolve(response.data);
              } else {
                if ((response.error.code == 2004 || response.error.code == 1000) && !authCheck) {
                  // window.location.reload();
                  reject(response.error);
                } else {
                  reject(response.error);
                }
              }
          if (subscription) {
            subscription.unsubscribe();
          }
        },
        error: (error) => {
          if (subscription) {
            subscription.unsubscribe();
          }
          reject(error);
        }
        });
      } else {
        reject();
      }
    })

    return {
      send: promise,
      cancel: () => {
        if(subscription){
          subscription.unsubscribe();
        }
      }
    };
  }


  upload(request: {
    endpoint: string,
    sender: string,
    receiver: string,
    body: Object,
    file: File,
    clusterName: ClusterNames
  }): Promise<any> {
    let uuid = this.checkIfUserLoggedIn();
    let platformCode = this.platformCode ?? null;
    let requestToken = this.tokenService.buildRequestToken({
      sender: request.sender,
      receiver: request.receiver,
      uuid: uuid,
      body: request.body,
      platform: platformCode
    });
    let fileFormData = new FormData();
    fileFormData.append('file', request.file, request.file.name);
    fileFormData.append('token', requestToken);
    let requestOptions = {
      withCredentials: true,
    }
    return new Promise<any>((resolve, reject) => {
      const apiGatewayUrl = this.buildURL(request);
      this.httpClient.post<IHttpResponse>(
        apiGatewayUrl,
        fileFormData,
        {withCredentials: true}
      ).subscribe({
        next: (response: IHttpResponse) => {
          if (response.success) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        },
        error: (error) => {
          reject(error);
        }
      });
    });
  }

  sendRequestWithoutToken<T>(request: IRequestOptions, authCheck?: boolean, skipPlatform?: boolean): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      if (isPlatformBrowser(this.platformId) || skipPlatform) {
        let requestOptions = {
          withCredentials: true,
        }
        const apiGatewayUrl = this.buildURL(request);
        this.httpClient.post<IHttpResponse>(
          apiGatewayUrl,
          request.body,
          requestOptions
        ).subscribe({
          next: (response: IHttpResponse) => {
            if (response.success) {
              resolve(response.data);
            } else {
              if ((response.error.code == 2004 || response.error.code == 1000) && !authCheck) {
                // window.location.reload();
                reject(response.error);
              } else {
                reject(response.error);
              }
            }
          },
          error: (error) => {
            reject(error);
          }
        });
      } else {
        reject();
      }
    });
  }

  getRequest<T extends object>(request: IRequestOptions, authCheck?: boolean): Promise<any> {
    const API_STATE_KEY = makeStateKey<string>(`${this.languageControl.getCurrentLanguage().code}/${request.endpoint}`);
    if (isPlatformBrowser(this.platformId)) {
      const savedResponseState = this.state.get<string | undefined>(API_STATE_KEY, undefined);
      if (savedResponseState) {
        this.state.remove<string>(API_STATE_KEY);
        const decodedResponseState = JSON.parse(this.encryptionService.decodeBase64ToString(savedResponseState));
        return this.processStateResponse(decodedResponseState, authCheck);
      }
    }
    return new Promise((resolve, reject) => {
      if (isPlatformServer(this.platformId) && request.skipOnSSR) return reject();
      let uuid = this.checkIfUserLoggedIn();
      let platformCode = this.platformCode ?? null;
      let legacyUnicornToken = this.tokenService.buildRequestToken({
        sender: request.sender,
        receiver: request.receiver,
        uuid: uuid,
        body: request.body,
        platform: platformCode
      });
      let requestOptions = {
        withCredentials: true,
        headers: {
          'Legacy-Authorization': legacyUnicornToken,
        }
      }
      const apiGatewayUrl = this.buildURL(request);
      this.httpClient.get<T | IHttpResponse>(
        apiGatewayUrl,
        requestOptions
      ).subscribe((response: T | IHttpResponse) => {
        if (isPlatformServer(this.platformId)) {
            const encodedResponseState = this.encryptionService.encodeStringToBase64(JSON.stringify(response));
            this.state.set<string>(API_STATE_KEY, encodedResponseState);
          }
          if(response === null) {
            resolve(response);

          }
          // this type check as we start to return data on the root response object
          // So we check if old response it will have success as a boolean
          // in old endpoints we will return the response.data
          else if (response && "success" in response) {
            if (response.success) {
              resolve(response.data);
            } else {
              reject(response.error);
            }
            // in new endpoints we will return the data from root response object
          } else if (!("success" in response)) {
            resolve(response);
          }
        }, (errorResponse: HttpErrorResponse) => {
          if (errorResponse.status === 0) {
           this.sentryService.reportHttpErrorResponse('Http Request Blocked', errorResponse);
          }
          // check if the old response is still return (old services)
          if (errorResponse?.error?.success === false) {
            reject(errorResponse.error.error);
          } else {
            reject(errorResponse);
          }
        });
    });
  }

  newSendRequest<T extends object>(request: IRequestOptions, authCheck?: boolean): Promise<any> {
    const API_STATE_KEY = makeStateKey<string>(`${this.languageControl.getCurrentLanguage().code}/${request.endpoint}`);
    if (isPlatformBrowser(this.platformId)) {
      const savedResponseState = this.state.get<string | undefined>(API_STATE_KEY, undefined);
      if (savedResponseState) {
        this.state.remove<string>(API_STATE_KEY);
        const decodedResponseState = JSON.parse(this.encryptionService.decodeBase64ToString(savedResponseState));
        return this.processStateResponse(decodedResponseState, authCheck);
      }
    }
    return new Promise((resolve, reject) => {
      if (isPlatformServer(this.platformId) && request.skipOnSSR) return reject();
      let uuid = this.checkIfUserLoggedIn();
      let platformCode = this.platformCode ?? null;
      let legacyUnicornToken = this.tokenService.buildRequestToken({
        sender: request.sender,
        receiver: request.receiver,
        uuid: uuid,
        body: {}, // TODO: to be discussed whever we are going to send an empty body in headers as well or not.
        platform: platformCode
      });
      let requestOptions = {
        withCredentials: true,
        headers: {
          'Legacy-Authorization': legacyUnicornToken,
        }
      }
      const apiGatewayUrl = this.buildURL(request);
      this.httpClient.post<T | IHttpResponse>(
        apiGatewayUrl,
        request.body,
        requestOptions
      ).subscribe((response: T | IHttpResponse) => {
          if (isPlatformServer(this.platformId)) {
            const encodedResponseState = this.encryptionService.encodeStringToBase64(JSON.stringify(response));
            this.state.set<string>(API_STATE_KEY, encodedResponseState);
          }
          if(response === null) {
            resolve(response)
          }
          // this type check as we start to return data on the root response object
          // So we check if old response it will have success as a boolean
          // in old endpoints we will return the response.data
          else if (response && "success" in response) {
            if (response.success) {
              resolve(response.data);
            } else {
              reject(response.error);
            }
            // in new endpoints we will return the data from root response object
          } else if (!("success" in response)) {
            resolve(response);
          }
        }, (errorResponse: HttpErrorResponse) => {
          if (errorResponse.status === 0) {
           this.sentryService.reportHttpErrorResponse('Http Request Blocked', errorResponse);
          }
          // check if the old response is still return (old services)
          if (errorResponse?.error?.success === false) {
            reject(errorResponse.error.error);
          } else {
            reject(errorResponse);
          }
        });
    });
  }

  deleteRequest<T extends object>(request: IRequestOptions, authCheck?: boolean): Promise<any> {
    const API_STATE_KEY = makeStateKey<string>(`${this.languageControl.getCurrentLanguage().code}/${request.endpoint}`);
    if (isPlatformBrowser(this.platformId)) {
      const savedResponseState = this.state.get<string | undefined>(API_STATE_KEY, undefined);
      if (savedResponseState) {
        this.state.remove<string>(API_STATE_KEY);
        const decodedResponseState = JSON.parse(this.encryptionService.decodeBase64ToString(savedResponseState));
        return this.processStateResponse(decodedResponseState, authCheck);
      }
    }
    return new Promise((resolve, reject) => {
      if (isPlatformServer(this.platformId) && request.skipOnSSR) return reject();
      let uuid = this.checkIfUserLoggedIn();
      let platformCode = this.platformCode ?? null;
      let legacyUnicornToken = this.tokenService.buildRequestToken({
        sender: request.sender,
        receiver: request.receiver,
        uuid: uuid,
        body: request.body,
        platform: platformCode
      });
      let requestOptions = {
        withCredentials: true,
        headers: {
          'Legacy-Authorization': legacyUnicornToken,
        }
      }
      const apiGatewayUrl = this.buildURL(request);
      this.httpClient.delete<T | IHttpResponse>(
        apiGatewayUrl,
        requestOptions
      ).subscribe((response: T | IHttpResponse) => {
          if (isPlatformServer(this.platformId)) {
            const encodedResponseState = this.encryptionService.encodeStringToBase64(JSON.stringify(response));
            this.state.set<string>(API_STATE_KEY, encodedResponseState);
          }
          if(response === null) {
            resolve(response)
          }
          // this type check as we start to return data on the root response object
          // So we check if old response it will have success as a boolean
          // in old endpoints we will return the response.data
          else if (response && "success" in response) {
            if (response.success) {
              resolve(response.data);
            } else {
              reject(response.error);
            }
            // in new endpoints we will return the data from root response object
          } else if (!("success" in response)) {
            resolve(response);
          }
        }, (errorResponse: HttpErrorResponse) => {
          if (errorResponse.status === 0) {
           this.sentryService.reportHttpErrorResponse('Http Request Blocked', errorResponse);
          }
          // check if the old response is still return (old services)
          if (errorResponse?.error?.success === false) {
            reject(errorResponse.error.error);
          } else {
            reject(errorResponse);
          }
        });
    });
  }

  private buildURL(request: IRequestOptions) {
    if (request.clusterName) {
      return `${environment.apiGatewayUrl}/${request.clusterName}/${request.endpoint}`;
    } else {
      return `${environment.apiGatewayUrl}/${request.endpoint}`;
    }
  }
}
