import { Injectable } from '@angular/core';
import {ITrackingOptions, TrackingEvent, TrackingEvents} from "@services/tracking/tracking.interface";
import {MixpanelService} from "@services/mixpanel/mixpanel.service";
import {ELoginTypes} from "@interfaces/authorized-user/user.interface";
import {GoogleTagManagerService} from "@services/marketing/google-tag-manager.service";

@Injectable({
  providedIn: 'root'
})
export class TrackingService {

  constructor(private mixpanelService: MixpanelService,
              private gtmService: GoogleTagManagerService
  ) { }

  sendEvent(event: TrackingEvent, options?: ITrackingOptions) {
    //TODO: to be removed after making sure all progress tracker events are working properly
    if(event.name.startsWith('progress_tracker')) {
      console.log({progressTrackerMixPanelEvent: event});
    }
    //this.mixpanelService.track(event, options);
    this.gtmService.sendCustomEvent(event.name,{
      properties: event.properties,
      clientName: 'data-client'
    });
  }

  sendSignUpStartedEvent(properties: TrackingEvents["signup with email started"]) {
    this.sendEvent({
      name: 'signup with email started',
      properties
    });
  }

  signupActivationEvent(properties: TrackingEvents["signup activation"]) {
    this.sendEvent({
      name: 'signup activation',
      properties
    });
  }

}
