import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GoogleAuthService} from "@services/social-auth/google-auth.service";
import {UiLoaderService} from "@services/ui-loader/ui-loader.service";
import {GoogleTagManagerService} from "@services/marketing/google-tag-manager.service";

@Component({
  selector: 'alm-root-google-button',
  templateUrl: './google-button.component.html',
  styleUrls: ['./google-button.component.scss']
})
export class GoogleButtonComponent implements AfterViewInit {

  @Input() title: string = '';
  @Input() googleId: string = '';
  @Input() redirectUrl: string = '';
  @Output() onGoogleAccessTokenReceive: EventEmitter<string> = new EventEmitter<string>();
  @Output() onGoogleCodeReceive: EventEmitter<string> = new EventEmitter<string>();

  constructor(private googleService: GoogleAuthService, private uiLoader: UiLoaderService, private gtmService: GoogleTagManagerService) {
  }

  ngAfterViewInit(): void {
    this.googleService.loadGoogleScript(this.googleId).then(() => {
      if (this.googleService.shouldCheckForGoogleLogin()) {
        let state = window.history.state;
        if (state.code) {
          this.onGoogleCodeReceive.emit(state.code);
        }
      }
    });
  }

  googleLogin() {
    this.uiLoader.startUiLoader('social-loader');
    this.googleService.getUserCodeFromGoogle(this.redirectUrl)
      .catch(() => {
        console.log('Google ERROR');
        this.uiLoader.stopUiLoader('social-loader');
      });
  }

}
