import {IEnvironmentNames} from '@interfaces/environment/environment.interface';
import {AlmentorClientEnvironment} from "@interfaces/common/almentor-client-environment.interface";

export const environment: AlmentorClientEnvironment = {
  production: false,
  environmentName: IEnvironmentNames.development,
  infrastructure: 'local',
  apiGatewayUrl: 'https://api-gateway.almentor-mail.com/api',
  platforms: {
    base: {link: 'almentor.dev'},
    anonymous: {link: 'https://www.almentor.dev'},
    b2c: {link: 'https://app.almentor.dev', code: '00'},
    admin: {link: 'https://admin.almentor.dev', code: '02'},
    dashboard: {link: 'https://mentor-dashboard.almentor.dev', code: '03'},
    ld: {link: 'https://orgadmin.almentor.dev', code: '05'},
    preview: {link: 'https://preview.almentor.dev', code: '02'},
    payment: {link: 'https://payment.almentor.dev', code: '2D'},
    marketing: {link: 'https://beta-business.almentor-mail.com'},
    sso: {link: 'https://account.almentor.dev'},
    futureX: {link: 'https://futurex.almentor.dev', code: '26'}
  },
  certificateValidator: 'https://dev-validate-certificate.almentor.me',
  cdnUrl: 'https://cdn-dev-revamp.almentor.me',
  connectorUrl: 'https://beta-auth.almentor.me',
  facebookId: '510357686322993',
  googleId: '539340182137-i5h9ua1rin5klnkmehskfm4cee6l79u1.apps.googleusercontent.com',
  paypalAppId: "ARxNfx97XybcxfPA8utn8IkY8sJGtgfsofnseKkDHFB6QQQrrHsbg07m-5LP_AZTUWRrsfRGDOoevSyE",
  bcPlayerConfig: {
    accountId: '6415782337001',
    playerId: 'jZNdE49vY'
  },
  firebase: {
    apiKey: "AIzaSyDEnnIJwM5-dudevBezKzbE87JGKNWsbEHM",
    authDomain: "almentor-revamp.firebaseapp.com",
    projectId: "almentor-revamp",
    storageBucket: "almentor-revamp.appspot.com",
    messagingSenderId: "539340182137",
    appId: "1:539340182137:web:bd1677fdc02977ca083aa8"
  },
  algolia: {
    apiKey: "da88ed68c2dcd599792d83b0f2c1505e",
    appId: "FRYEJSU9VH",
    indices: {
      keywords: "revamp_stg_KEYWORDs",
      courses: "revamp_stg_Course",
      publicAndPrivateCourses: "revamp_stg_Public_And_Private_Course",
      publicAndPrivateCoursesMentors: "revamp_stg_Public_And_Private_Course_Mentor",
      bundles: "revamp_stg_Bundle",
      mentors: "revamp_stg_Mentor"
    }
  },
  gtmID: "GTM-K7TWMZF",
  amplitudeApiKey: "48dd76bdd0280ee485912234d433d374",
  braze: {
    apiKey: '84c4b8da-627b-4674-afc0-9e86a12265f0',
    baseUrl: 'sdk.fra-01.braze.eu'
  },
  isrServerConfig: {
    maxAge: 30, // seconds null | 0 | > 0
    staleDuration: 10, // seconds null | 0 | > 0
    errorDuration: 0, // seconds null | 0 | > 0,
  },
  mixpanel: {
    b2cToken: '5dd8964c087a8c75131d8f3fbe1541b9',
    b2bToken: '5dd8964c087a8c75131d8f3fbe1541b9',
    debug: true
  },
  paymobIframes: {
    standalone: '773458',
    embedded: '818915'
  },
  checkout:{
    cdn: 'https://cdn.checkout.com/js/framesv2.min.js',
    publicKey: 'pk_sbox_6q43fyuay627ldw25g7765ndhif',
  }
};
