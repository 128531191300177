import {ILanguage, ILanguageCode} from "@interfaces/common/language.interface";
import {ELanguage, ETheme} from "@interfaces/common/connector.interface";

export const AVAILABLE_LANGUAGES: ILanguage[] = [
  {
    id: 1,
    name: "English",
    code: ELanguage.EN,
    direction: "ltr"
  },
  {
    id: 2,
    name: "العربية",
    code: ELanguage.AR,
    direction: "rtl"
  }
];

export const DEFAULT_LANGUAGE: ILanguage = AVAILABLE_LANGUAGES[1];
export const DEFAULT_LANGUAGE_CODE: ILanguageCode = DEFAULT_LANGUAGE.code;
export const DEFAULT_THEME: ETheme = ETheme.Dark;
