import {Injectable} from '@angular/core';
import {IUsp} from "@interfaces/usp.interface";

export interface SubscriptionPlan {
    id: number | string;
    name: string;
}

@Injectable({
    providedIn: 'root'
})
export class SubscriptionMarketingService {

    subscriptionPlans: SubscriptionPlan[] = [
        {id: 1, name: 'Egypt Monthly Plan'},
        {id: 2, name: 'Egypt Yearly Plan'},
        {id: 3, name: 'Rest of countries Monthly Plan'},
        {id: 4, name: 'Rest of countries Yearly Plan'},
        {id: 5, name: 'UAE Monthly Plan'},
        {id: 6, name: 'UAE Yearly Plan'},
        {id: 7, name: 'Saudi Arabia Monthly Plan'},
        {id: 8, name: 'Saudi Arabia Yearly Plan'},
        {id: 9, name: 'Qatar Monthly Plan'},
        {id: 10, name: 'Qatar Yearly Plan'},
        {id: 11, name: 'Egypt Monthly Plan'},
        {id: 12, name: 'Egypt Yearly Plan'},
        {id: 13, name: 'UAE Monthly Plan'},
        {id: 14, name: 'UAE Yearly Plan'},
        {id: 15, name: 'Saudi Arabia Monthly Plan'},
        {id: 16, name: 'Saudi Arabia Yearly Plan'},
        {id: 17, name: 'Qatar Monthly Plan'},
        {id: 18, name: 'Qatar Yearly Plan'},
        {id: 19, name: 'Rest of countries Monthly Plan'},
        {id: 20, name: 'Rest of countries Yearly Plan'},
        {id: 21, name: 'Egypt Monthly Plan'},
        {id: 22, name: 'Egypt Yearly Plan'},
        {id: 23, name: 'UAE Monthly Plan'},
        {id: 24, name: 'UAE Yearly Plan'},
        {id: 25, name: 'Saudi Arabia Monthly Plan'},
        {id: 26, name: 'Saudi Arabia Yearly Plan'},
        {id: 27, name: 'Qatar Monthly Plan'},
        {id: 28, name: 'Qatar Yearly Plan'},
        {id: 29, name: 'Rest of countries Monthly Plan'},
        {id: 30, name: 'Rest of countries Yearly Plan'},
        {id: 31, name: 'Egypt Quarterly plan'},
        // new subscription service plans
        {id: "c3f5ac5f-09b0-4602-b2b3-85a311f3d7b7", name: "Yearly Plan"},
        {id: "803cd10d-7fa9-40f1-861b-1eaacdb5b8be", name: "Quarterly Plan"},
        {id: "b364eb8d-54c9-4232-8fd1-2f6d3bd88fb4", name: "Monthly Plan"},
    ];

    getSubscriptionPlanName(id: number | string): string {
        return this.subscriptionPlans.find(plan => plan.id === id)?.name || 'N/A';
    }
}
