export interface IUserInfo {
  uuid: string;
  name: string;
  email: string;
  interests: boolean,
  hasPurchasedCourse: boolean;
  subscribed: boolean;
  isMobileVerified: boolean;
  subscriptionStatus: number;
  renewalDate: string;
  isMentor: boolean;
  userType: IUserType;
  cuuid: string;
  hasDemographicData: boolean;
  loginCountDown: number,
  firstAuthAfterSubscription: boolean
}

export enum IUserType {
  B2C=1,
  B2B,
  FACEBOOK,
  GOOGLE
}

export enum ELoginTypes {
  EMAIL = 'email',
  FACEBOOK = 'facebook',
  GOOGLE = 'google'
}
