import {Directive, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {MixpanelService} from "@services/mixpanel/mixpanel.service";
import {ITrackingOptions, TrackingEvent} from "@services/tracking/tracking.interface";
import {OnChange} from "../../decorators/onchange.decorator";
import {TrackingService} from "@services/tracking/tracking.service";

@Directive({
  selector: '[trackingClickEvent]'
})
export class TrackingClickEventDirective implements OnChanges {
  @Input() trackingClickEvent?: TrackingEvent;
  @Input() navigationEvent: boolean = false;
  @Output() action: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
  private isClicked: boolean = false;

  @HostListener('click', ['$event']) onClick($event: MouseEvent){
    if (this.trackingClickEvent) {
      this.sendEvent(this.trackingClickEvent, {
        navigationEvent: this.navigationEvent
      });
    } else {
      this.isClicked = true;
    }
    if (!this.action) return;
    if (this.navigationEvent) {
      setTimeout(() => {
          this.action.emit($event);
      }, 300);
    } else {
      this.action.emit($event);
    }
  }
  constructor(
      private trackingService: TrackingService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.hasOwnProperty('trackingClickEvent')) return;
    if (this.isClicked && this.trackingClickEvent) {
      this.sendEvent(this.trackingClickEvent);
      this.isClicked = false;
    }
  }

  sendEvent(event: TrackingEvent, options?: ITrackingOptions) {
    console.log('sendEvent', event.name);
    this.trackingService.sendEvent(event, options);
  }

}
