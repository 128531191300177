<div class="footer-almentor__wrapper">
  <h5 class="footer-almentor__wrapper_title">
    {{'footer.footer_almentor_links_title' | translate}}
  </h5>
  <div class="footer-almentor__wrapper_links-list">
    <a href="https://about.almentor.net/" target="_blank">
      {{'footer.footer_almentor' | translate | translateCut: 0}}
    </a>
    <a href="https://about.almentor.net/blog" target="_blank">
      {{'footer.footer_almentor' | translate | translateCut: 3}}
    </a>
    <a href="https://about.almentor.net/meet-the-team" target="_blank">
      {{'footer.footer_almentor' | translate | translateCut: 5}}
    </a>
    <a href="https://about.almentor.net/contact" target="_blank">
      {{'footer.footer_almentor' | translate | translateCut: 1}}
    </a>
  </div>
</div>
