import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {IUserInfo} from '@interfaces/authorized-user/user.interface';
import {IUserPrivileges} from '@interfaces/privileges/privileges.interface';
import {ActivatedRoute, ActivatedRouteSnapshot, Params, Router} from '@angular/router';
import {LanguageControlService} from '@services/language/language-control.service';
import {UserStorageService} from '@services/user/user-storage.service';
import {ConnectorV2Service} from '@services/connector/connector-v2.service';
import {GoogleTagManagerService} from '@services/marketing/google-tag-manager.service';
import {AuthV2Service, IAuthorizedResponse} from '@services/integrations/auth-v2/auth-v2.service';
import {PrivilegesControlService} from '@services/privileges/privileges-control.service';
import {DialogService} from '@services/custom-dialogs/dialog.service';
import {MarketingService} from '@services/integrations/marketing/marketing.service';
import {BrazeUserEventsService} from '@services/marketing/braze/events/braze-user-events.service';
import {ConversionStrings} from '@interfaces/marketing/marketing.interface';
import {filter, take} from 'rxjs/operators';
import {CustomHttpService} from '@services/request-token/custom-http.service';
import {environment} from '@environments/environment';
import {IEnvironmentPlatforms, IUserPlatform} from '@interfaces/user/user-common.interface';
import {EUserType} from '@interfaces/common/connector.interface';


export interface IUserAuthorizedResponse {
  authorized: boolean,
  reachedDevicesLimit: boolean,
  isAllBlocked?: boolean,
  firstAuthAfterSubscription: boolean
}

@Injectable({
  providedIn: 'root'
})
export class UserManagerService {

  userInfo: BehaviorSubject<IUserInfo | null> = new BehaviorSubject<IUserInfo | null>(null);
  userImage: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined)
  userPrivileges: BehaviorSubject<IUserPrivileges | null> = new BehaviorSubject<IUserPrivileges | null>(null);
  userPlatform: string | null = null;
  isFirstLogin: BehaviorSubject<{ loaded: boolean, isFirstLogin: boolean }> = new BehaviorSubject<{ loaded: boolean, isFirstLogin: boolean }>({
    isFirstLogin: false,
    loaded: false
  });

  constructor(private languageControl: LanguageControlService, private router: Router,
              private storageService: UserStorageService, private connectorService: ConnectorV2Service,
              private googleTagManagerService: GoogleTagManagerService, private auth2Service: AuthV2Service,
              private privilegesControl: PrivilegesControlService,
              private dialogService: DialogService, private marketingService: MarketingService, private route: ActivatedRoute,
              private brazeUserEventsService: BrazeUserEventsService,
              private customHttpService: CustomHttpService,
              ) {
  }

  checkUserAuthorization(route: ActivatedRouteSnapshot, platform: string | null, update?: boolean):
    Promise<IUserAuthorizedResponse> {
    return new Promise<IUserAuthorizedResponse>((resolve) => {
      let currentUser = this.userInfo.getValue();
      if (currentUser && !update) {
        resolve({authorized: true, reachedDevicesLimit: false, firstAuthAfterSubscription: false});
      } else {
        // routeUUID mainly used for futureX integration to auth user with uuid from saml redirect after server set the cookies
        const routeUUID = route.queryParams.uuid;
        if (routeUUID) this.setConnectorUserUUID(routeUUID);
        let localUser = this.connectorService.connectorUser.getValue();
        if (localUser?.uuid) {
          this.auth2Service.authorize('authorized', platform, platform ? null : location.hostname.split('.')[0])
            .then((authResponse) => {
              this.customHttpService.platformCode = authResponse.platform;
              this.checkIfUserLoggedIn(authResponse, currentUser).then(resolve);
            }).catch((e) => {
            resolve({authorized: false, reachedDevicesLimit: false, firstAuthAfterSubscription: false});
          });
        } else {
          resolve({authorized: false, reachedDevicesLimit: false, firstAuthAfterSubscription: false});
        }
      }
    });
  }

  getB2COrB2BUserPlatform(userPlatforms: IUserPlatform[]) :IUserPlatform | undefined {
    const environmentPlatforms: { [key: string]: IEnvironmentPlatforms } = environment.platforms;
    const standardPlatforms = Object.keys(environmentPlatforms).map(platformKey => {
      const platform = environmentPlatforms[platformKey];
      return {
        id: platformKey,
        code: platform.code,
        link: platform.link,
      }
    });
    return userPlatforms.find(userPlatform => {
      return userPlatform.platform === environment.platforms.b2c.code
        || standardPlatforms.filter(p => p.id !== 'b2c').every(p => p.code !== userPlatform.platform)
    });
  }


  private addUserFromAd() {
    let queryParams$: Observable<Params>;
    // condition to catch Query params only one time
    if (window.location.search) {
      queryParams$ = this.route.queryParams.pipe(filter(p =>  !!Object.keys(p).length), take(1));
    } else {
      queryParams$ = this.route.queryParams.pipe(take(1));
    }
    queryParams$.subscribe((params: Params) => {
      const conversionStrings = Object.values(ConversionStrings);
      if (Object.keys(params).some(p => conversionStrings.includes(p as ConversionStrings))) {
        this.marketingService.createAdConversionCookie(params);
      }
      let adSourceInfo = this.marketingService.checkForAdConversionCookie();
      if (adSourceInfo) {
        this.marketingService.addUserFromAd('user-service', JSON.parse(adSourceInfo)).then((data) => {
          this.marketingService.deleteAdConversionCookie();
        }).catch((e) => {
        })
      } else {
        this.marketingService.getUserAd('user-service');
      }
    });
  }

  private checkIfUserLoggedIn(authorizedResponse: IAuthorizedResponse, currentUser: IUserInfo | null): Promise<IUserAuthorizedResponse> {
    return new Promise<IUserAuthorizedResponse>(resolve => {
      this.setUserPrivileges(authorizedResponse.privilege);
      if (authorizedResponse.isLogged && !authorizedResponse.forceLogout) {
        this.userPlatform = authorizedResponse.platform;
        this.addUserFromAd();
        if (currentUser) {
          resolve({
            authorized: true,
            reachedDevicesLimit: authorizedResponse.isReachedMaxDevices,
            isAllBlocked: authorizedResponse.isUserBlockAll,
            firstAuthAfterSubscription: authorizedResponse.firstAuthAfterSubscription
          });
        } else {
          this.setUserInfo(authorizedResponse).then(resolve);
        }
      } else {
        if (authorizedResponse.forceLogout) {
          this.auth2Service.logUserOut('guard').finally(() => {
            this.connectorService.removeUser();
            resolve({
              authorized: false,
              reachedDevicesLimit: authorizedResponse.isReachedMaxDevices,
              isAllBlocked: authorizedResponse.isUserBlockAll,
              firstAuthAfterSubscription: authorizedResponse.firstAuthAfterSubscription
            });
          })
        } else {
          resolve({
            authorized: false,
            reachedDevicesLimit: authorizedResponse.isReachedMaxDevices,
            isAllBlocked: authorizedResponse.isUserBlockAll,
            firstAuthAfterSubscription: authorizedResponse.firstAuthAfterSubscription
          });
        }
      }
    });
  }

  private setUserInfo(authorizedResponse: IAuthorizedResponse): Promise<IUserAuthorizedResponse> {
    return new Promise<IUserAuthorizedResponse>(resolve => {
      this.auth2Service.getUserInfo('auth')
        .then((userInfo) => {
          if (userInfo) {
            userInfo.cuuid = userInfo.uuid;
            userInfo.subscribed = userInfo.subscriptionStatus == 2 || userInfo.subscriptionStatus == 3;
            userInfo.firstAuthAfterSubscription = authorizedResponse.firstAuthAfterSubscription;
            this.userInfo.next(userInfo);
            this.isFirstLogin.next({loaded: true, isFirstLogin: authorizedResponse.isFisrtLogin});
            resolve({
              authorized: true,
              reachedDevicesLimit: authorizedResponse.isReachedMaxDevices,
              isAllBlocked: authorizedResponse.isUserBlockAll,
              firstAuthAfterSubscription: authorizedResponse.firstAuthAfterSubscription
            });
          } else {
            resolve({authorized: false, reachedDevicesLimit: false, firstAuthAfterSubscription: false});
          }
        }).catch(() => {
        resolve({authorized: false, reachedDevicesLimit: false, firstAuthAfterSubscription: false});
      })
    });
  }

  private setUserPrivileges(privilegesToken: string) {
    this.userPrivileges.next(this.privilegesControl.getUserPrivileges(privilegesToken));
  }

  setConnectorUserUUID(routeUUID: string) {
    const user = {
      uuid: routeUUID,
      type: this.customHttpService.platformCode === environment.platforms.b2c.code? EUserType.B2C:  EUserType.B2B,
      link: window.location.href
    }
    this.connectorService.addUser(user);
    this.connectorService.connectorUser.next(user)
  }
}
