import { Injectable } from '@angular/core';
import {IOrderDetails} from '@interfaces/shopping-cart/shopping-cart-list.interface';
import {BrazeService} from '@services/marketing/braze/braze.service';
import {SubscriptionMarketingService} from '@services/marketing/subscription-marketing.service';
import {IPaymentMethod, PaymentMethods} from '@interfaces/common/payment.interface';
import {IPaymentBase, PaymentDetails, SubscriptionPaymentRequest} from "@interfaces/payment/payment.interface";

interface CheckoutDetails {
  planId: number | string;
  paymentMethod: IPaymentMethod;
  couponCode?: string;
  finalAmountUSD: number
}
@Injectable({
  providedIn: 'root'
})
export class BrazeSubscriptionEventsService {

  constructor(
    private brazeService: BrazeService,
    private subscriptionMarketingService: SubscriptionMarketingService,
  ) { }

  logSubscriptionCheckout(checkoutDetails: CheckoutDetails) {
    this.brazeService.logEvent('subscription_checkout', {
      plan: this.subscriptionMarketingService.getSubscriptionPlanName(checkoutDetails.planId),
      payment_method: checkoutDetails.paymentMethod,
      discount_code: checkoutDetails.couponCode,
      amount_after_discount: checkoutDetails.finalAmountUSD,
    });
  }
}
