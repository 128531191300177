import {environment} from "@environments/environment";


export const USER_PREFERENCES_COOKIE_KEY = 'alm-user';

export const SUBSCRIPTION_INITIATOR_URL = 'alm-sub-init-url';

export const ALGOLIA_ANONYMOUS_USER_TOKEN = 'alm-algolia-device-token';
export const LOCAL_SEARCH_HISTORY = 'Alm-LSH';

export const BASE_LINK_DOMAIN = environment.platforms.base.link;
export const BASE_LINK_PATH = '/';
export const BASE_LINK_SAMESITE = false;
export const BASE_LINK_SECURE = true;

export const baseLinkCookieOptions = Object.freeze({
  domain: BASE_LINK_DOMAIN,
  path: BASE_LINK_PATH,
  sameSite: BASE_LINK_SAMESITE,
  secure: BASE_LINK_SECURE,
});
