import {Inject, Injectable, PLATFORM_ID, Renderer2, RendererFactory2} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {ILanguage, ILanguageCode} from '@interfaces/common/language.interface';
import {BehaviorSubject, Observable} from "rxjs";
import {DOCUMENT, Location} from '@angular/common';
import {ConnectorV2Service} from "@services/connector/connector-v2.service";
import {ELanguage} from "@interfaces/common/connector.interface";
import {AVAILABLE_LANGUAGES, DEFAULT_LANGUAGE, DEFAULT_LANGUAGE_CODE} from "@constants/ui.constants";


@Injectable({
  providedIn: 'root'
})
export class LanguageControlService {

  private languageList: ILanguage[] = AVAILABLE_LANGUAGES;
  private renderer: Renderer2;
  currentLanguage: BehaviorSubject<ILanguage> = new BehaviorSubject<ILanguage>(DEFAULT_LANGUAGE);

  constructor(private translateService: TranslateService,
              private rendererFactory: RendererFactory2,
              private connectorService: ConnectorV2Service,
              @Inject(PLATFORM_ID) private platformId: any,
              @Inject(DOCUMENT) private document: Document,
              private location: Location
              ) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.getAllLanguages().then((languages: ILanguage[]) => {
      this.languageList = languages;
      this.translateService.addLangs(this.getLanguagesCodes());
    });
  }

  get allLanguageCodes() :ILanguageCode[] {
    return AVAILABLE_LANGUAGES.map(language => language.code);
  }

  private getLanguage(lang: ILanguageCode): ILanguage | undefined {
    return this.languageList.find(language => {
      return language.code === lang;
    });
  }

  private getLanguagesCodes(): string[] {
    return this.languageList.map(language => {
      return language.code;
    });
  }

  private changeWebsiteDirection(direction: 'rtl' | 'ltr'): void {
      this.renderer.setAttribute(this.document.body, 'dir', direction);
  }

  getCurrentLanguage(): ILanguage {
    return this.currentLanguage.getValue()
  }

  getAllLanguages(): Promise<ILanguage[]> {
    return new Promise<ILanguage[]>(resolve => {
      if (this.languageList.length > 0)
        resolve(this.languageList);
      else
        resolve(AVAILABLE_LANGUAGES);
    });
  }

  getLanguageName(lang: number): ILanguage {
    return this.languageList.filter(language => {
      return language.id == lang;
    })[0];
  }

  /**
   * Sets translations for a specific language.
   *
   * @param {string} lang - The language code for the translations.
   * @param {Object} translations - An object containing the translations for the specified language.
   *
   * @return {void}
   */
  setTranslations(lang: string, translations: Object): void {
    this.translateService.setTranslation(lang, translations, true);
  }

  /**
   * Change the language and redirect to the new language URL.
   *
   * @param {ILanguageCode} languageCode - The language code to change to.
   * @return {void}
   */
  changeLanguageAndRedirect(languageCode: ILanguageCode): void {
    this.changeLanguage(languageCode);
    const urlPath = this.getUrlPathWithLanguage(window.location.pathname, languageCode);
    console.log('changeLanguageAndRedirect.urlPath', urlPath)
    this.location.replaceState(urlPath + window.location.search);
  }

  /**
   * Constructs a new URL Path with the provided language code.
   *
   * @param {string} path - The original URL Path.
   * @param {ILanguageCode} languageCode - The language code to use in the URL.
   * @return {string} The new URL with the language code.
   */
  public getUrlPathWithLanguage(path: string, languageCode: ILanguageCode): string {
      // Parse the URL
      const urlParts = path.split('/');

      // Check if the first segment is a language code and remove it
      if (Object.values(ELanguage).includes(urlParts[1] as ELanguage)) {
          urlParts.splice(1, 1);
      }

      // Reconstruct the URL with the new language code
      return languageCode === DEFAULT_LANGUAGE_CODE
          ? urlParts.join('/')
          : [`/${languageCode}`, ...urlParts.slice(1)].join('/');
  }

  /**
   * Changes the language of the website based on the provided language code.
   *
   * @param {ILanguageCode} languageCode - The language code to change to.
   * @returns {void}
   */
  changeLanguage(languageCode: ILanguageCode): void {
    let selectedLanguage = this.getLanguage(languageCode);
    if (!selectedLanguage) return;
    this.connectorService.changeSelectedLanguage(selectedLanguage.code);
    this.translateService.use(selectedLanguage.code);
    this.changeWebsiteDirection(selectedLanguage.direction);
    this.currentLanguage.next(selectedLanguage);
  }

  translate(key: string): Observable<string> {
    return this.translateService.get(key);
  }
}
